.mission-overlay {
    color: #fff;
    background: #007bff;
    max-width: 530px;
    float: right;
    margin-top: -140px;
    position: relative;
    padding: 10px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .mission-overlay {
        color: #fff;
        background: none;
        float: none;
        margin-top:0;
        position: relative;
        padding: 10px;
        max-width:none;
    }
}