.banner {
    /*position: absolute;*/
    top: 61px;
    width: 100%;
    left: 0;
    margin-top:56px;
}
.banner__image {
    width:100%;
}
.banner__menu {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
}

.banner__mission {
    height: 200px;
    background-color: red;
    /* bottom: 0; */
    /* right: 0; */
    /* position: inherit; */
    /* bottom: 167px; */
    z-index: 1000;
    position: absolute;
    /*margin-top: -401px;*/
}