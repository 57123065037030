.photos {
    width: 100%;
    padding-inline-start: 0px;
}

.photos__item {
    display: inline-block;
    margin: 21px;
    cursor:pointer;
}
