.contact-us {
    color:#fff;
}
.contact-us__general-content {
    margin-bottom: 50px;
}
@media screen and (max-width: 750px) {
    .contact-us__map {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
    }
}